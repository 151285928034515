<div class="sidebar-panel">
  <div id="scroll-area" class="navigation-hold" fxLayout="column">
    <div class="branding">
      <a [routerLink]="['/test/reports/view']">
        <img src="assets/images/logo-ebo-icon-80x80.png" alt="" class="app-logo-small" />
      </a>
    </div>
    <app-sidenav
      [items]="menuItems"
      [hasIconMenu]="hasIconTypeMenuItem"
      [iconMenuTitle]="iconTypeMenuTitle"
      (closeMenu)="toggleCollapse()"
    ></app-sidenav>
    <div class="sidenav-bottom">
      <button [disableRipple]="true" mat-icon-button id="collapseToggle" (click)="toggleCollapse()" class="toggle-collapsed">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <span class="app-version">v {{ appVersion }}</span>
  </div>
</div>
