<div class="sidenav-hold">
  <div class="icon-menu" *ngIf="hasIconTypeMenuItem">
    <!-- Icon menu separator -->
    <div class="mt-1 mb-1 nav-item-sep">
      <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
      <span class="text-muted icon-menu-title">{{iconTypeMenuTitle}}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of menuItems">
      <button
        *ngIf="!item.disabled && item.type === 'icon'"
        mat-raised-button
        [matTooltip]="item.tooltip"
        routerLink="/{{item.state}}"
        routerLinkActive="mat-bg-primary"
      >
        <mat-icon>{{item.icon}}</mat-icon>
      </button>
    </div>
  </div>

  <ul appDropdown class="sidenav">
    <ng-container *ngFor="let item of menuItems">
      <li appDropdownLink [class.open]="isLinkActive(item)" [hidden]="item.disabled">
        <div class="nav-item-sep" *ngIf="item.type === 'separator'">
          <mat-divider></mat-divider>
          <span class="text-muted">{{item.name}}</span>
        </div>
        <div *ngIf="item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
          <a matRipple *ngIf="item.type === 'test-bot'" (click)="openSidebarRight()">
            <mat-icon>{{item.icon}}</mat-icon>
            <span class="item-name lvl1">{{item.name}}</span>
            <span fxFlex></span>
            <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges"
              >{{ badge.value }}</span
            >
          </a>
          <!-- here -->
          <a
            (click)="closeMainMenu()"
            routerLink="/{{item.state}}"
            matRipple
            *ngIf="item.type === 'link'"
            [ngClass]="'menu-item-' + item.name"
          >
            <mat-icon>{{item.icon}}</mat-icon>
            <span class="item-name lvl1">{{item.name}}</span>
            <span fxFlex></span>
            <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges"
              >{{ badge.value }}</span
            >
          </a>
          <a [href]="item.state" matRipple *ngIf="item.type === 'extLink'" target="_blank">
            <mat-icon>{{item.icon}}</mat-icon>
            <span class="item-name lvl1">{{item.name}}</span>
            <span fxFlex></span>
            <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges"
              >{{ badge.value }}</span
            >
          </a>

          <!-- DropDown -->
          <a *ngIf="item.type === 'dropDown'" matRipple>
            <mat-icon>{{item.icon}}</mat-icon>
            <span class="item-name lvl1">{{item.name}}</span>
            <span fxFlex></span>
            <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges"
              >{{ badge.value }}</span
            >
            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
          </a>
          <!-- LEVEL 2 -->
          <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
            <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
              <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
                <span class="item-name lvl2">{{itemLvL2.name}}</span>
                <span fxFlex></span>
              </a>

              <a *ngIf="itemLvL2.type === 'dropDown'" matRipple>
                <span class="item-name lvl2">{{itemLvL2.name}}</span>
                <span fxFlex></span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
              </a>

              <!-- LEVEL 3 -->
              <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                  <a
                    routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                    matRipple
                  >
                    <span class="item-name lvl3">{{itemLvL3.name}}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
