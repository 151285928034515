import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserPermissionsService } from '../../core/services';
import { Subscription } from 'rxjs';

/**
 * To use multiple claims, you must use it like this
 * *appHasPermission="['x.view', 'x.edit']"
 * If one of them exist then it returns true
 */

@Directive({
  selector: '[appHasPermission]'
})
export class AccessByClaimDirective implements OnDestroy {
  private permissions: string[] = [];
  private permissionsSub: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: UserPermissionsService
  ) {
    this.permissionsSub = this.permissionsService.permissions$.subscribe(() => {
      this.updateView();
    });
  }

  @Input()
  set appHasPermission(permissions: string | string[]) {
    this.permissions = Array.isArray(permissions) ? permissions : [permissions];
    this.updateView();
  }

  private updateView(): void {
    if (this.permissionsService.hasAnyPermission(this.permissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy(): void {
    this.permissionsSub.unsubscribe();
  }
}
