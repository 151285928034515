import { Routes } from '@angular/router';

// App
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { BotEventType } from 'app/domain/dtos/configuration/BotEventType';

// Root Singleton Services
import { AuthGuard, PermissionsGuard } from 'app/core/services';
import { RoleGuard } from 'app/auth/sessions/role.guard';
import { RedirectComponent } from './views/redirect/redirect.component';
import { HangfireRedirectComponent } from './views/redirect/hangfire-redirect.component';
import { FeatureFlagGuard } from './auth/sessions/feature-flag.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'redirect',
        component: RedirectComponent
      },
      {
        path: 'hangfire',
        component: HangfireRedirectComponent
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        data: { title: 'Virtual agent settings', breadcrumb: 'Virtual agent settings' }
      },
      {
        path: 'alerts',
        loadChildren: () => import('./views/events/events.module').then(m => m.EventsModule),
        data: { title: 'Alerts', breadcrumb: 'Alerts', botEventType: BotEventType.Alert }
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        data: { title: 'Users', breadcrumb: 'Users' }
      },
      {
        path: 'chatusers',
        loadChildren: () => import('./views/chatusers/chatusers.module').then(m => m.ChatUsersModule),
        data: { title: 'ChatUsers', breadcrumb: 'ChatUsers' }
      },
      {
        path: 'goals',
        loadChildren: () => import('./views/events/events.module').then(m => m.EventsModule),
        data: { title: 'Goals', breadcrumb: 'Goals', botEventType: BotEventType.Goal }
      },

      {
        path: 'plugins',
        loadChildren: () => import('./views/settings/plugins/plugins.module').then(m => m.AppsModule),
        canActivate: [RoleGuard],
        data: { title: 'Plugins', breadcrumb: 'Plugins' }
      },
      {
        path: 'virtual-agents',
        loadChildren: () => import('./views/bots/bot.module').then(m => m.BotModule),
        data: { title: 'Create virtual agent', breadcrumb: 'Create virtual agent', permission: 'bot.access' },
        canActivate: [PermissionsGuard]
      },
      {
        path: 'cors',
        loadChildren: () => import('./views/cors/cors.module').then(m => m.CorsModule),
        data: { title: 'CORS', breadcrumb: 'CORS' }
      },
      {
        path: 'template-response',
        loadChildren: () => import('./views/template-response/template-response.module').then(m => m.TemplateResponseModule),
        data: { title: 'Template Responses', breadcrumb: 'Template Responses' }
      },
      {
        path: 'conversation-settings',
        loadChildren: () => import('./views/conversation-settings/conversation-settings.module').then(m => m.ConversationSettingsModule),
        canActivate: [RoleGuard],
        data: { title: 'Conversation Settings', breadcrumb: 'Conversation Settings' }
      },
      {
        path: 'organisation-units',
        loadChildren: () => import('./views/organisation-units/organisation-units.module').then(m => m.OrganisationUnitsModule),
        canActivate: [RoleGuard],
        data: { title: 'Organisation Units', breadcrumb: 'Organisation Units' }
      },
      {
        path: 'permissions',
        loadChildren: () => import('./views/permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate: [RoleGuard],
        data: { title: 'Permissions', breadcrumb: 'Permissions' }
      },
      {
        path: 'customization',
        loadChildren: () => import('./views/customization/customization.module').then(m => m.CustomizationModule),
        canActivate: [RoleGuard],
        data: { title: 'Customization', breadcrumb: 'Customization' }
      },
      {
        path: ':environment',
        children: [
          {
            path: 'dialogues',
            loadChildren: () => import('./views/dialogues/dialogue.module').then(m => m.DialogueModule),
            data: { title: 'Dialogs', breadcrumb: 'Dialogs', permission: 'dialogs.view' },
            canActivate: [PermissionsGuard]
          },
          {
            path: 'client-reports',
            loadChildren: () => import('./views/client-reports/client-reports.module').then(m => m.ClientReportsModule),
            data: { title: 'Client Reports', breadcrumb: 'Client Reports', permission: 'client-reports.view' },
            canActivate: [PermissionsGuard]
          },
          {
            path: 'reports',
            loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule),
            data: { title: 'Reports', breadcrumb: 'Reports' }
          },
          {
            path: 'chat',
            loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule),
            data: { title: 'Test virtual agent', breadcrumb: '' }
          },
          {
            path: 'conversations',
            loadChildren: () => import('./views/conversation/conversations.module').then(m => m.ConversationsModule),
            data: { title: 'Conversations', breadcrumb: 'Conversations', permission: 'conversations.view' },
            canActivate: [PermissionsGuard]
          },
          {
            path: 'waiting-list',
            loadChildren: () => import('./views/waiting-list/waiting-list.module').then(m => m.WaitingListModule),
            canActivate: [FeatureFlagGuard],
            data: { title: 'Waiting list', breadcrumb: 'Waiting List', featureFlag: 'waitingList' }
          },
          {
            path: '',
            redirectTo: '/',
            pathMatch: 'full'
          }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];
