import { Component, OnInit } from '@angular/core';
import { NotificationModel } from './models/notification.model';
import { NotificationsResolver } from './services/notificationsResolver';
import { ConnectionResolver } from '../../../core/services';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store';
import * as fromRoot from '../../../core/store';

@Component({
  selector: 'ebo-notifications',
  styleUrls: ['./notifications.component.scss'],
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  openMenu = false;
  isTasksLoad = false;
  notifications: Array<NotificationModel> = [];
  notifCounter = 0;
  userId$: Observable<string>;

  constructor(
    private connectionResolver: ConnectionResolver,
    public notificationsResolver: NotificationsResolver,
    private store: Store<fromRoot.AppState>
  ) {}

  ngOnInit(): void {
    this.userId$ = this.store.pipe(select(fromAuth.getUserId));
    if (localStorage.getItem('globalNotifications') && localStorage.getItem('expirationTimestamp')) {
      this.retrieveExpirationTimeOfNotifs(JSON.parse(localStorage.getItem('expirationTimestamp')));

      this.isTasksLoad = JSON.parse(localStorage.getItem('globalNotifications')).filter(notif => notif.isLoaded === true).length > 0;
      this.notifCounter = JSON.parse(localStorage.getItem('globalNotifications')).filter(notif => notif.read === false).length;
    } else {
      this.setLocalStorageWithTime();
    }

    this.notificationsResolver.notificationsHub.subscribe(object => {
      if (object !== null) {
        this.addNotification(object);
      }
    });

    document.addEventListener('click', event => {
      if (
        event.target['id'] === 'notifications-box' ||
        event.target['id'] === 'notifications-icon' ||
        event.target['id'] === 'notifications-box-active' ||
        event.target['id'] === 'notifications-icon-active'
      ) {
        this.displayHideNotif();
      } else if (
        this.openMenu &&
        !event.target['classList'].contains('notif-container') &&
        !event.target['classList'].contains('notif-item') &&
        !event.target['classList'].contains('notif-icon') &&
        !event.target['classList'].contains('time') &&
        !event.target['classList'].contains('message') &&
        !event.target['classList'].contains('mat-list-text')
      ) {
        this.displayHideNotif();
      }
    });

    this.connectionResolver.conversationHubStatus$.subscribe(() => {
      if (this.connectionResolver.conversationHub.state === 'Connected') {
        this.userId$.subscribe(userId => {
          this.connectionResolver.conversationHub.invoke('joinNotificationsGroup', userId).catch(err => console.table(err));
        });
      }
    });
  }

  addNotification(notification: any): void {
    if (Object.keys(notification).length !== 0) {
      const findNotifSameName = this.notifications.findIndex(
        notif => notif.id === notification.id && notif.isLoaded !== notification.isLoaded
      );

      if (findNotifSameName !== -1) {
        this.notifications[findNotifSameName].isLoaded = false;
      }

      this.notifications = [notification, ...this.notifications];

      if (localStorage.getItem('globalNotifications')) {
        localStorage.setItem('globalNotifications', JSON.stringify(this.notifications));
      }

      this.notifCounter = this.notifications.filter(notif => notif.read === false).length;
      this.isTasksLoad = this.notifications.filter(notif => notif.isLoaded === true).length > 0;
    }
  }

  displayHideNotif(): void {
    this.openMenu = !this.openMenu;
  }

  readItem(item: NotificationModel): void {
    this.openMenu = true;
    const getItemIndexInArray = this.notifications.findIndex(notif => notif === item);
    this.notifications[getItemIndexInArray].read = true;

    this.notifCounter = this.notifications.filter(notif => notif.read === false).length;
    localStorage.setItem('globalNotifications', JSON.stringify(this.notifications));
  }

  setLocalStorageWithTime(): void {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1); // Available only 1 day
    const expirationTimestamp = expirationDate.getTime();
    localStorage.setItem('expirationTimestamp', String(expirationTimestamp));
    localStorage.setItem('globalNotifications', JSON.stringify(this.notifications));
  }

  retrieveExpirationTimeOfNotifs(storedExpirationTimestamp: any): void {
    const expirationTimestamp = parseInt(storedExpirationTimestamp, 10);
    const currentTimestamp = new Date().getTime();

    if (currentTimestamp > expirationTimestamp) {
      localStorage.removeItem('expirationTimestamp');
      localStorage.removeItem('globalNotifications');
      this.setLocalStorageWithTime();
    } else {
      this.notifications = JSON.parse(localStorage.getItem('globalNotifications'));
    }
  }
}
