import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

// Root Singleton Services
import { SessionStorageService } from 'app/core/services/sessionStorage.service';
import { UsersService } from '../../views/users/users.service';
import { LoginResponseObj } from '../../domain/loginResponseObj';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  currentUser: LoginResponseObj;
  userObjectKey = 'userObject';

  constructor(private router: Router, private sessionStorage: SessionStorageService, private userService: UsersService) {
    const userObject = new LoginResponseObj();
    const sessionStorageItem = this.sessionStorage.get(this.userObjectKey);
    this.currentUser = userObject.parseFromSessionStorage(sessionStorageItem);
  }

  canActivate(): Observable<boolean> {
    return this.userService.getUserRoles(this.currentUser.userId).pipe(
      first(),
      map(obj => {
        // @ts-ignore
        if (obj.roles.filter(name => name === 'Admin' || name === 'EboAdmin').length > 0) {
          return true;
        } else {
          this.router.navigate(['/settings/view']);
        }
      })
    );
  }
}
